import * as React from "react";
import {
  Box,
  Flex,
  HStack,
  useDisclosure,
  Stack,
  IconButton,
  Collapse,
  Text,
  Fade,
  SlideFade,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import { Link } from "gatsby";
import { AnimatePresence, motion } from "framer-motion";
import Sidebar from "./Sidebar";
import withLocation from "../utils/withLocation";
import { useEffect } from "react";

const Links = [
  { label: "Accueil", path: "/" },
  { label: "Projets", path: "/projets" },
  { label: "Contact", path: "/contact" },
];

const NavLink = ({ children, path, onClose }) => {
  function changePage() {
    onClose && onClose();
    setTimeout(() => {
      window.scrollTo(0, 0);
      const body = document.getElementsByTagName("body")[0];
      body.scrollTop = 0;
      return false;
    }, 450);
  }
  return (
    <Box
      className="navlink"
      color={{ base: "#fff", lg: "#060616" }}
      fontFamily="satoshi"
      fontWeight={400}
      px={2}
      py={1}
    >
      <Link
        onClick={() => changePage()}
        activeStyle={{ fontWeight: 600 }}
        to={path}
      >
        {children}
      </Link>
    </Box>
  );
};

function Navbar() {
  const { isOpen, onClose, onOpen } = useDisclosure();
  return (
    <Box
      bg={{ base: "#060616", lg: "none" }}
      w="100%"
      margin="auto"
      zIndex={2}
      position="fixed"
      px={4}
    >
      <Flex h={20} alignItems={"center"} justifyContent={"center"}>
        <Text
          display={{ lg: "none" }}
          mr="auto"
          fontFamily="satoshi"
          color="#fff"
          fontWeight={700}
        >
          GFT Production ©
        </Text>
        <IconButton
          zIndex={999}
          size="md"
          icon={isOpen ? <CloseIcon /> : <HamburgerIcon fontSize={24} />}
          aria-label="Open Menu"
          display={{ lg: "none" }}
          bg="none"
          color="#fff"
          _hover={{ bg: "none" }}
          onClick={isOpen ? onClose : onOpen}
        />
        <HStack spacing={8} alignItems={"center"}>
          <HStack as="nav" spacing={20} display={{ base: "none", lg: "flex" }}>
            {Links.map(({ label, path }) => (
              <NavLink path={path} key={label}>
                {label}
              </NavLink>
            ))}
          </HStack>
        </HStack>
      </Flex>

      <Collapse animateOpacity offsetY="80px" direction="top" in={isOpen}>
        <Box pb={4} display={{ lg: "none" }}>
          <Stack color="#fff" as={"nav"} spacing={4}>
            {Links.map(({ label, path }) => (
              <NavLink onClose={onClose} path={path} key={label}>
                {label}
              </NavLink>
            ))}
          </Stack>
        </Box>
      </Collapse>
    </Box>
  );
}

function Layout({ children, location, search }) {
  const { idVideo } = search;

  useEffect(() => {
    window.scroll(0, 0);
  }, [location]);

  return (
    <>
      <Fade
        transition={{
          exit: { duration: 0.5 },
          enter: { duration: 0.25 },
        }}
        in={!idVideo}
      >
        <Navbar />
      </Fade>
      <SlideFade
        transition={{
          exit: {
            delay: 0.74,
            duration: 1,
            ease: [0.58, 0, 0.23, 1],
          },
          enter: { duration: 0.5, ease: [0.58, 0, 0.23, 1] },
        }}
        in={!idVideo}
        offsetX="-500px"
      >
        <Sidebar />
      </SlideFade>

      <AnimatePresence initial={false} mode="wait">
        <motion.main
          key={location.pathname}
          exit={{ opacity: 0 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          {children}
        </motion.main>
      </AnimatePresence>
    </>
  );
}

export default withLocation(Layout);
